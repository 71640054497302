import GUI from "lil-gui";
import * as THREE from "three";
import gsap from "gsap";

//debug
const gui = new GUI();
const debugObject = {}

const parameters = {
  materialColor: "#ff0000",
};

gui.addColor(parameters, "materialColor");
gui.onChange(() => {
  material.color.set(parameters.materialColor);
  particlesMaterial.color.set(parameters.materialColor);
});

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// texture
const textureLoader = new THREE.TextureLoader();
const gradientTexture = textureLoader.load("textures/gradients/3.jpg");
gradientTexture.magFilter = THREE.NearestFilter;

// material
const material = new THREE.MeshToonMaterial({
  color: parameters.materialColor,
  gradientMap: gradientTexture,
  wireframe: true
});

gui.add(material, 'wireframe')
gui.close();

// meshes
const objectDistance = 4;
const mesh1 = new THREE.Mesh(new THREE.IcosahedronGeometry(2, 10), material);
const mesh2 = new THREE.Mesh(new THREE.ConeGeometry(.3, 0.7, 7), material);
const mesh3 = new THREE.Mesh(new THREE.TorusGeometry(0.2, 0.12, 25, 16),material);

mesh2.position.y = 1.2;
mesh3.position.y = -2;

mesh1.position.x = 2;
mesh2.position.x = -1;
mesh3.position.x = -0.5;

mesh3.position.z = -5;

scene.add(mesh1, mesh2, mesh3);

//debug
gui.add(mesh1.position, 'y').name('SphereGeometry').min(- 3).max(3).step(0.01)
gui.add(mesh2.position, 'y').name('ConeGeometry').min(- 3).max(3).step(0.01)
gui.add(mesh3.position, 'y').name('TorusGeometry').min(- 3).max(3).step(0.01)

const sectionMeshes = [mesh1, mesh2, mesh3];

debugObject.spin = () =>
{
    gsap.to(mesh3.rotation, { duration: 1, y: mesh3.rotation.y + Math.PI * 2 })
    gsap.to(mesh1.rotation, { duration: 1, y: mesh3.rotation.y + Math.PI * 2 })
    gsap.to(mesh2.rotation, { duration: 1, y: mesh3.rotation.y + Math.PI * 2 })
}
gui.add(debugObject, 'spin')

//particles
const particlesCount = 550;
const positions = new Float32Array(particlesCount * 3);
for (let i = 0; i < particlesCount; i++) {
  positions[i * 3 + 0] = (Math.random() - 0.5) * 10;
  positions[i * 3 + 1] =
    objectDistance * 0.4 -
    Math.random() * objectDistance * sectionMeshes.length;
  positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
}

const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute(
  "position",
  new THREE.BufferAttribute(positions, 3),
);

const particlesMaterial = new THREE.PointsMaterial({
  color: parameters.materialColor,

  size: 0.04,
});

const particles = new THREE.Points(particlesGeometry, particlesMaterial);
scene.add(particles);

// lights
const directionalLight = new THREE.DirectionalLight("#ffffff", 3);
directionalLight.position.set(1, 1, 0);
scene.add(directionalLight);

//sizes
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

//group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup)

//camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100,
);
camera.position.z = 6;
cameraGroup.add(camera);

//renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

//scroll
let scrollY = window.scrollY;
let currentSection = 0;

window.addEventListener("scroll", () => {
  scrollY = window.scrollY;
  const newSection = Math.round(scrollY / sizes.height);
  if (newSection != currentSection) {
    currentSection = newSection;
    gsap.to(sectionMeshes[currentSection].rotation, {
      duration: 1.5,
      ease: "power2.inOut",
      x: "+=6",
      y: "+=3",
      z: "+=1.5",
    });
  }
});

//cursor
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener("mousemove", (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = event.clientY / sizes.height - 0.5;
});

// animate
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // animated camera
  camera.position.y = (-scrollY / sizes.height) * objectDistance;

  const parallaxX = cursor.x;
  const parallaxY = -cursor.y;

  cameraGroup.position.x +=
    (parallaxX - cameraGroup.position.x) * 5 * deltaTime;

  cameraGroup.position.y +=
    (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

  //animated meshes
  for (const mesh of sectionMeshes) {
    mesh.rotation.x += deltaTime * 0.1;
    mesh.rotation.y += deltaTime * 0.12;
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
